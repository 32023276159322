<template>
    <section class="view privacy">
        <view-title v-bind:title="$t('message.privacy.title')"></view-title>
        <div class="container py-5">
            <div class="row">
                <div class="col-md-3">
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'en'">
                        <li class="nav-item">
                            <a class="nav-link" href="#personal-information-collection-and-use">
                                Personal information collection and use
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#cookies">
                                Cookies
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#information-sharing-and-disclosure">
                                Information sharing and disclosure
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#confidentiality-and-security">
                                Confidentiality and Security
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#data-retention-period">
                                Data Retention Period
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#cross-border-transfer">
                                Cross-border transfer
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#amendments">
                                Amendments
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'zh-hk'">
                        <li class="nav-item">
                            <a class="nav-link" href="#personal-information-collection-and-use">
                                個人資料獲取及使用
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#cookies">
                                Cookies
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#information-sharing-and-disclosure">
                                資料分享及披露
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#confidentiality-and-security">
                                保密與安全
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#data-retention-period">
                                資料保留期限
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#cross-border-transfer">
                                跨境資料傳送
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#amendments">
                                條款修改
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">
                                聯絡我們
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'zh-cn'">
                        <li class="nav-item">
                            <a class="nav-link" href="#personal-information-collection-and-use">
                                个人资料获取及使用
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#cookies">
                                Cookies
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#information-sharing-and-disclosure">
                                资料分享及披露
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#confidentiality-and-security">
                                保密与安全
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#data-retention-period">
                                资料保留期限
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#cross-border-transfer">
                                跨境资料传送
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#amendments">
                                条款修改
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">
                                联络我们
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'en'">
                    <p class="text-justify">
                        I-Skincare Company Limited (“marier Skincare”, “marier Salon”, “we”, “our” or “us”) takes your privacy seriously.  This policy covers how we treat personal information that we collect and receive both online and off-line, including information related to your membership registration and enquiry, past use of www.marierskincare.com & salon.mariersalon.com and information that you provide at our beauty treatment centers , concept stores and online shopping platform (www.marierskincare.com).  Personal Information is any information about you that is personally identifiable like your name, address, email address, identity card number, phone number, medical record, and includes those that is not otherwise publicly available.
                    </p>
                    <p class="text-justify">
                        When we collect your Personal Information online, a Personal Information Collection Statement will appear on the screen of your electronic device or you will be reminded if you are giving your Personal Information at our beauty treatment centres and our concept store.  You will be given the choice to reject giving us your personal information and you are to remind that we may not be able to accommodate your request and/or Service, if you refuse to provide us with the required information.
                    </p>
                    <p class="text-justify">
                        By visiting and/or continuing to visit our Website, you agree that we will collect, use, and store your personal information in accordance with this Privacy Policy, as amended from time to time. If you do not agree to this Privacy Policy, please do not use our Website. Your continued use of our Website following the posting of changes to this policy will be deemed as your acceptance of those changes.
                    </p>
                    <p class="text-justify">
                        This Privacy Policy is incorporated into and forms part of our Terms of Use. We have structured our Website so that, in general, you can visit us without identifying yourself or revealing any personal information.  Once you choose to voluntarily provide us with your Personal Information, you can be assured that it will only be used to support your customer relationship with mariter Skincare.
                    </p>
                    <p class="text-justify">
                        You may wish to access, verify, update any of your Personal Information we hold. To do so, please contact our customer service (contacts set out below).
                    </p>

                    <h5 id="personal-information-collection-and-use">
                        Personal information collection and use
                    </h5>
                    <p class="text-justify">
                        We use your personal information for the following purposes:
                    </p>
                    <ul>
                        <li class="text-justify">
                            to provide customer services;
                        </li>
                        <li class="text-justify">
                            to follow up on your enquiries and Services;
                        </li>
                        <li class="text-justify">
                            send online and offline promotional offers and newsletters;
                        </li>
                        <li class="text-justify">
                            to contact you through email, phone, SMS, WhatsApp and other online social media/platforms;
                        </li>
                        <li class="text-justify">
                            improve our Services;
                        </li>
                        <li class="text-justify">
                            for marketing and promotions of our Services;
                        </li>
                        <li class="text-justify">
                            data analysis and market research;
                        </li>
                        <li class="text-justify">
                            to enhance and improve the content of our Website;
                        </li>
                        <li class="text-justify">
                            to identify the usage trends of our Website;
                        </li>
                        <li class="text-justify">
                            to analyze the effectiveness of our marketing campaigns.
                        </li>
                    </ul>
                    <p class="text-justify">
                        You can opt-out of receiving direct marketing promotions from us Tel. (+852)3583-2928 . Opt-out procedures will be completed within 10 working days upon received of your request. No fee will be charged for the cancellation arrangement.
                    </p>
                    <p class="text-justify">
                        We are not responsible for information received other than required.  This information will be regarded as non-confidential and we shall have the right to disclose or delete at our discretion with no payment or any compensation to the information providers.  If information received might be considered as misconduct or unlawful acts, the law enforcement authority will be notified at our discretion and they may commence investigation.  We reserve the right to terminate your account and take up further legal action.
                    </p>

                    <h5 id="cookies">
                        Cookies
                    </h5>
                    <p class="text-justify">
                        We use cookies as a device on our Website to collect your browsing history automatically, such as time spent on the Website, pages visited, language preferences, etc., to allow us to recognize the electronic device that you used in order to help make your recurring use of our Website more convenient.  We also use cookies to gather statistical information about Website usage to enhance and improve the content of the Website, our Services and analyze the effectiveness of our marketing campaigns.
                    </p>
                    <p class="text-justify">
                        Most browsers accept cookies automatically and you may configure the browser of your electronic device to disable cookies.  Please be reminded that by doing so you may not be able to use all features and services offered on our Website.
                    </p>
                    <p class="text-justify">
                        Whenever users visit our Website, we collect IP addresses automatically through our server log files.  These IP addresses are collected for calculating Website usage levels, administering the Website and helping to solve server problems when they occur.
                    </p>

                    <h5 id="information-sharing-and-disclosure">
                        Information sharing and disclosure
                    </h5>
                    <p class="text-justify">
                        We do not rent, sell, or share personal information about you with other people or non affiliated companies except to provide Services that you have requested or under the following circumstances:
                    </p>
                    <ul>
                        <li class="text-justify">
                            We provide the information to trusted partners who work on behalf of or with us under confidentiality agreements to process your personal information and maintain our database;
                        </li>
                        <li class="text-justify">
                            Any financial institutions, charge or credit card issuing companies, credit information or reference bureau, or collection agencies where necessary to establish and support the payment of any products and services being requested by you;
                        </li>
                        <li class="text-justify">
                            We respond to subpoenas, court orders, or legal processes, or to establish or exercise our legal rights or defend against legal claims; and
                        </li>
                        <li class="text-justify">
                            We believe it is necessary to share such information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, violations of our Terms and Conditions of Use, or as otherwise required by law.
                        </li>
                    </ul>

                    <h5 id="confidentiality-and-security">
                        Confidentiality and Security
                    </h5>
                    <ul>
                        <li class="text-justify">
                            We limit access to personal information about you to our staff, business service providers, and business related partners, who we believe reasonably need to come into contact with that information to provide Services to you or in order to do their jobs;
                        </li>
                        <li class="text-justify">
                            www.marierskincare.com supports encryption when transmitting certain kinds of information, such as personal information, financial services information or payment information;
                        </li>
                        <li class="text-justify">
                            We will take reasonable steps to protect your personal information, and you are reminded that no online or offline data transmission or data storage system can be guaranteed to be 100% safe and risk free.
                        </li>
                    </ul>

                    <h5 id="data-retention-period">
                        Data Retention Period
                    </h5>
                    <p class="text-justify">
                        We will only retain your personal information for the period that is necessary to fulfill the purposes stated in this Privacy Policy, except if it is permitted by the law or required to fulfill legal obligation.  Data that is no longer required will be disposed of in a safe and proper manner.
                    </p>

                    <h5 id="cross-border-transfer">
                        Cross-border transfer
                    </h5>
                    <p class="text-justify">
                        Your personal information may be processed and stored in locations other than in Hong Kong for our business operations if needed; under such circumstances reasonable security of your personal information will be guaranteed.
                    </p>

                    <h5 id="amendments">
                        Amendments
                    </h5>
                    <p class="text-justify">
                        marier Skincare reserves the right to change any of the terms of this Privacy Policy at any time at our sole discretion.  Any changes of the Terms of Use (Terms and Conditions of Use and Privacy Policy) will be effective upon its posting on the Website and the date of “Last Updated” will be shown on the bottom of the revised page.   Your continued use of our Website following the posting of the revised or amended terms shall signify your acceptance of such changes.
                    </p>

                    <h5 id="contact">Contact Us</h5>
                    <p class="text-justify">
                        Should you have any questions regarding to the Terms of Use, please feel free to contact us during office hours from Monday to Friday at 9am – 6pm at:
                    </p>

                    <dt>
                        Email:
                    </dt>
                    <dd>
                        marketing@marierskincare.com
                    </dd>
                    <dt>
                        Phone:
                    </dt>
                    <dd>
                        (+852)3583-2928
                    </dd>
                    <dt>
                        Fax:
                    </dt>
                    <dd>
                        (+852)2619-9972
                    </dd>

                    <p class="text-justify">
                        This Privacy Policy shall be governed by the law of the Hong Kong Special Administrative Region.
                    </p>
                    <p class="text-justify">
                        Last updated on January 31, 2018.
                    </p>

                    <p class="text-justify">
                        I-skincare Company Limited.  All rights reserved.
                    </p>
                    <p class="text-justify">
                        Use of this site signifies your agreement to the Terms and Conditions of Use and Privacy Policy.
                    </p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-hk'">
                    <p class="text-justify">
                        I-Skincare Company Limited (“marier Skincare”, "曼肌兒", “marier Salon”, “我們")對於閣下之私隱非常重視。本私隱政策涵蓋我們如何處理從線上及線下獲得的個人資料，這些資料包括閣下會員登記資料，查詢資料，過往使用旗下網站( www.marierskincare.com & salon.mariersalon.com)以及閣下在我們概念店，專櫃及網上購物平台 (www.marierskincare.com) 提供的資料。個人資料一般指個人之姓名、地址、電郵地址、身份證號碼、電話號碼、病歷、以及其它不能在公共地方獲取的個人資料。
                    </p>
                    <p class="text-justify">
                        當我們在線上或閣下在我們美容店、概念店及專櫃提供我們需要閣下提供個人資料時將會有提示通知。而閣下同時亦有拒絕提供相關資料的權力，若閣下拒絕提供我們需要的資料有可能令我們不能提供閣下全面或部分的要求或服務。
                    </p>
                    <p class="text-justify">
                        通過瀏覽我們的網站，即表示閣下確認和同意本私隱政策。我們會定期審視本私隱政策，並且不時地通過更新本網站來修訂私隱政策的規定。如閣下不同意本私隱政策，請勿使用本網站。如在本私隱政策修改後，閣下繼續使用我們的網站，將視為同意本經修訂後的私隱政策。
                    </p>
                    <p class="text-justify">
                        本網站經特別建構，一般而言，當閣下瀏覽我們的網站時，閣下無需公開身份或透露任何個人資訊。當閣下一旦選擇提供閣下的個人身份資訊（即能夠辨識閣下身份的任何資訊）給我們，我們保證該資訊只會被用在協助加強閣下和我們之間的客戶關係。
                    </p>
                    <p class="text-justify">
                        如果閣下希望就查詢、確認或更新任何個人資料，請聯絡我們的客戶服務（聯絡方法見下文）。
                    </p>

                    <h5 id="personal-information-collection-and-use">
                        個人資料獲取及使用
                    </h5>
                    <p class="text-justify">
                        我們使用個人資料的目的包括以下：
                    </p>
                    <ul>
                        <li class="text-justify">
                            提供客戶服務；
                        </li>
                        <li class="text-justify">
                            跟進閣下查詢及服務；
                        </li>
                        <li class="text-justify">
                            提供及發送線上及線下促銷優惠及通訊；
                        </li>
                        <li class="text-justify">
                            通過電郵，電話，短訊，WhatsApp或其它網絡社交媒介/平台與閣下聯絡；
                        </li>
                        <li class="text-justify">
                            提升我們提供的服務；
                        </li>
                        <li class="text-justify">
                            進行市場推廣及宣傳我們提供的服務；
                        </li>
                        <li class="text-justify">
                            資料分析及市場研究；
                        </li>
                        <li class="text-justify">
                            提升及改進我們網站的内容；
                        </li>
                        <li class="text-justify">
                            確定我們網站的使用趨勢；和
                        </li>
                        <li class="text-justify">
                            分析我們營銷活動的有效性。
                        </li>
                    </ul>
                    <p class="text-justify">
                        如閣下不想收取以上訊息可致電 (852)3583-2928 提出申請取消。在收到閣下的申請後，我們會在十個工作天内完成相關程序。此退出選擇是不會收取任何費用。
                    </p>
                    <p class="text-justify">
                        我們不會對閣下所提供之不相關的個人資料作出任何責任。而這類資料亦將不被視爲機密，並可能被公開或刪除而我們亦不會對提供者作出任何付款或補償。若有關資料性質屬不當或會構成非法行爲，我們將交由執法部門處理。我們亦保留把閣下的戶口終止及採取進一步的法律行動的權利。
                    </p>

                    <h5 id="cookies">
                        Cookies
                    </h5>
                    <p class="text-justify">
                        我們會利用Cookies自動收集閣下在本網站之瀏覽記錄，如在網站的瀏覽時間及頁面，語言選擇等，目的在於識別閣下所用的電子設備讓閣下使用同一設備時從反本網站時帶來方便。我們亦會用Cookies收集資料後作出統計關於網站使用率，目的在於提升本網站的内容及分析我們營銷活動的有效性。
                    </p>
                    <p class="text-justify">
                        一般瀏覽器都會自動接受Cookies，而閣下亦可在閣下的電子設備内關掉Cookies的功能。 但同時閣下有可能不能瀏覽及使用本網站全部網頁及功能。
                    </p>
                    <p class="text-justify">
                        本網站亦會通過我們使用的伺服器把使用者的IP地址自動記錄，目的在於計算本網站的使用量，管理網站及幫助解決伺服器的問題。
                    </p>

                    <h5 id="information-sharing-and-disclosure">
                        資料分享及披露
                    </h5>
                    <p class="text-justify">
                        我們不會出租，售賣或分享閣下的個人資料予不相關的第三者或非聯營公司，除非是要為提供閣下所需或要求的服務或基於以下原因：
                    </p>
                    <ul>
                        <li class="text-justify">
                            我們在保密協議的前提下只會提供資料予可靠商業夥伴協助或代表我們來處理閣下的個人資料以及保持和管理我們的數據庫；
                        </li>
                        <li class="text-justify">
                            就閣下要求的產品和服務建立和支持所需的付款設施，及在有需要時向相關的金融機構、記帳或信用卡發卡公司、信貸資料或參考機構或託收機構作出披露
                        </li>
                        <li class="text-justify">
                            當我們需要回應傳票、法庭命令、法律程序或展開或行使我們的法律權利或回應法律訴訟；和
                        </li>
                        <li class="text-justify">
                            當我們相信提供資料能協助調查、防止或防範非法活動、懷疑詐騙、違反本使用條款及細則、或配合法例上的需要的前提下，我們將會分享及披露相關資料予相關人士及機構。
                        </li>
                    </ul>

                    <h5 id="confidentiality-and-security">
                        保密與安全
                    </h5>
                    <ul>
                        <li class="text-justify">
                            我們限制及只允許須為閣下提供服務或有必要獲得閣下個人資料才能完成其工作的相關員工，商業服務提供者及商業夥伴閲讀及使用閣下的個人資料；
                        </li>
                        <li class="text-justify">
                            本網站採用加密系統，當傳送某些資料，如個人資料，付款資料等將會被加密；
                        </li>
                        <li class="text-justify">
                            我們會用合理步驟保障閣下的個人資料，但同時亦希望閣下理解我們不能擔保任何線上或線下的資料傳送方式能確保百分百安全及無誤。
                        </li>
                    </ul>

                    <h5 id="data-retention-period">
                        資料保留期限
                    </h5>
                    <p class="text-justify">
                        我們只會在合理及必須的時間内保留閣下的個人資料去完成在本私隱政策内所提及的目的，但在法例允許或在履行法律義務的前提下例外。我們會用安全及適當方式把不用保留的個人資料消毀。
                    </p>

                    <h5 id="cross-border-transfer">
                        跨境資料傳送
                    </h5>
                    <p class="text-justify">
                        閣下的個人資料可能會在我們商業運作上有需要時會被傳送到香港以外的其他地區或國家作處理及保全，而我們會確保所有資料在合理及安全情況下被傳送及保全。
                    </p>

                    <h5 id="amendments">
                        條款修改
                    </h5>
                    <p class="text-justify">
                        我們保留在有需要時修改或更新本網站提供的條款之權利。如條款有任何修改或更生，閣下可在頁尾能看到更生及生效日期。而被修改或更生的内容亦將同時生效。閣下在修改或更生條款後繼續瀏覽或使用本網站即代表閣下同意相關修改或更生。
                    </p>

                    <h5 id="contact">聯絡我們</h5>
                    <p class="text-justify">
                        如閣下對本條款有任何疑問，請在辦公時間星期一至五早上9點至下午6點，通過以下方式聯絡我們：
                    </p>

                    <dt>
                        電郵：
                    </dt>
                    <dd>
                        marketing@marierskincare.com
                    </dd>
                    <dt>
                        電話：
                    </dt>
                    <dd>
                        (+852)3583-2928
                    </dd>
                    <dt>
                        傳真：
                    </dt>
                    <dd>
                        (+852)2619-9972
                    </dd>

                    <p class="text-justify">
                        本私隱政策受香港特別行政區法律監管。
                    </p>
                    <p class="text-justify">
                        最後更新日期為 2018 年 1 月 31 日
                    </p>

                    <p class="text-justify">
                        I-Skincare Company Limited 保留一切權力。
                    </p>
                    <p class="text-justify">
                        使用此網站表示閣下同意接受本使用條款及細則和私隱政策。
                    </p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-cn'">
                    <p class="text-justify">
                        I-Skincare Company Limited (“marier Skincare”, "曼肌兒", “marier Salon”, “我们")对于阁下之私隐非常重视。本私隐政策涵盖我们如何处理从在线及线下获得的个人资料，这些数据报括阁下会员登记数据，查询数据，过往使用旗下网站( www.marierskincare.com & salon.mariersalon.com)以及阁下在我们概念店，专柜及网上购物平台 (www.marierskincare.com) 提供的数据。个人资料一般指个人之姓名、地址、电邮地址、身份证号码、电话号码、病历、以及其它不能在公共地方获取的个人资料。
                    </p>
                    <p class="text-justify">
                        当我们在在线或阁下在我们美容店、概念店及专柜提供我们需要阁下提供个人资料时将会有提示通知。而阁下同时亦有拒绝提供相关数据的权力，若阁下拒绝提供我们需要的数据有可能令我们不能提供阁下全面或部分的要求或服务。
                    </p>
                    <p class="text-justify">
                        通过浏览我们的网站，即表示阁下确认和同意本私隐政策。我们会定期审视本私隐政策，并且不时地通过更新本网站来修订私隐政策的规定。如阁下不同意本私隐政策，请勿使用本网站。如在本私隐政策修改后，阁下继续使用我们的网站，将视为同意本经修订后的私隐政策。
                    </p>
                    <p class="text-justify">
                        本网站经特别建构，一般而言，当阁下浏览我们的网站时，阁下无需公开身份或透露任何个人信息。当阁下一旦选择提供阁下的个人身份信息（即能够辨识阁下身份的任何信息）给我们，我们保证该信息只会被用在协助加强阁下和我们之间的客户关系。
                    </p>
                    <p class="text-justify">
                        如果阁下希望就查询、确认或更新任何个人资料，请联络我们的客户服务（联络方法见下文）。
                    </p>

                    <h5 id="personal-information-collection-and-use">
                        个人资料获取及使用
                    </h5>
                    <p class="text-justify">
                        我们使用个人资料的目的包括以下：
                    </p>
                    <ul>
                        <li class="text-justify">
                            提供客户服务；
                        </li>
                        <li class="text-justify">
                            跟进阁下查询及服务；
                        </li>
                        <li class="text-justify">
                            提供及发送在线及线下促销优惠及通讯；
                        </li>
                        <li class="text-justify">
                            通过电邮，电话，短讯，WhatsApp或其它网络社交媒介/平台与阁下联
                        </li>
                        <li class="text-justify">
                            提升我们提供的服务；
                        </li>
                        <li class="text-justify">
                            进行市场推广及宣传我们提供的服务；
                        </li>
                        <li class="text-justify">
                            资料分析及市场研究；
                        </li>
                        <li class="text-justify">
                            提升及改进我们网站的内容；
                        </li>
                        <li class="text-justify">
                            确定我们网站的使用趋势；和
                        </li>
                        <li class="text-justify">
                            分析我们营销活动的有效性。
                        </li>
                    </ul>
                    <p class="text-justify">
                        如阁下不想收取以上讯息可致电 (852)3583-2928 提出申请取消。在收到阁下的申请后，我们会在十个工作天内完成相关程序。此退出选择是不会收取任何费用。
                    </p>
                    <p class="text-justify">
                        我们不会对阁下所提供之不相关的个人资料作出任何责任。而这类数据亦将不被视为机密，并可能被公开或删除而我们亦不会对提供者作出任何付款或补偿。若有关资料性质属不当或会构成非法行为，我们将交由执法部门处理。我们亦保留把阁下的户口终止及采取进一步的法律行动的权利。
                    </p>

                    <h5 id="cookies">
                        Cookies
                    </h5>
                    <p class="text-justify">
                        我们会利用Cookies自动收集阁下在本网站之浏览记录，如在网站的浏览时间及页面，语言选择等，目的在于识别阁下所用的电子设备让阁下使用同一设备时从反本网站时带来方便。我们亦会用Cookies收集数据后作出统计关于网站使用率，目的在于提升本网站的内容及分析我们营销活动的有效性。
                    </p>
                    <p class="text-justify">
                        一般浏览器都会自动接受Cookies，而阁下亦可在阁下的电子设备内关掉Cookies的功能。 但同时阁下有可能不能浏览及使用本网站全部网页及功能。
                    </p>
                    <p class="text-justify">
                        本网站亦会通过我们使用的服务器把用户的IP地址自动记录，目的在于计算本网站的使用量，管理网站及帮助解决服务器的问题。
                    </p>

                    <h5 id="information-sharing-and-disclosure">
                        数据分享及披露
                    </h5>
                    <p class="text-justify">
                        我们不会出租，售卖或分享阁下的个人资料予不相关的第三者或非联营公司，除非是要为提供阁下所需或要求的服务或基于以下原因：
                    </p>
                    <ul>
                        <li class="text-justify">
                            我们在保密协议的前提下只会提供资料予可靠商业伙伴协助或代表我们来处理阁下的个人资料以及保持和管理我们的数据库；
                        </li>
                        <li class="text-justify">
                            就阁下要求的产品和服务建立和支持所需的付款设施，及在有需要时向相关的金融机构、记账或信用卡发卡公司、信贷资料或参考机构或托收机构作出披露；
                        </li>
                        <li class="text-justify">
                            当我们需要响应传票、法庭命令、法律程序或展开或行使我们的法律权利或响应法律诉讼；和
                        </li>
                        <li class="text-justify">
                            当我们相信提供数据能协助调查、防止或防范非法活动、怀疑诈骗、违反本使用条款及细则、或配合法例上的需要的前提下，我们将会分享及披露相关资料予相关人士及机构。
                        </li>
                    </ul>

                    <h5 id="confidentiality-and-security">
                        保密与安全
                    </h5>
                    <ul>
                        <li class="text-justify">
                            我们限制及只允许须为阁下提供服务或有必要获得阁下个人资料才能完成其工作的相关员工，商业服务提供商及商业伙伴阅读及使用阁下的个人资料；
                        </li>
                        <li class="text-justify">
                            本网站采用加密系统，当传送某些数据，如个人资料，付款数据等将会被加密；
                        </li>
                        <li class="text-justify">
                            我们会用合理步骤保障阁下的个人资料，但同时亦希望阁下理解我们不能担保任何在线或线下的数据传送方式能确保百分百安全及无误。
                        </li>
                    </ul>

                    <h5 id="data-retention-period">
                        数据保留期限
                    </h5>
                    <p class="text-justify">
                        我们只会在合理及必须的时间内保留阁下的个人资料去完成在本私隐政策内所提及的目的，但在法例允许或在履行法律义务的前提下例外。我们会用安全及适当方式把不用保留的个人资料消毁。
                    </p>

                    <h5 id="cross-border-transfer">
                        跨境数据传送
                    </h5>
                    <p class="text-justify">
                        阁下的个人资料可能会在我们商业运作上有需要时会被传送到香港以外的其他地区或国家作处理及保全，而我们会确保所有资料在合理及安全情况下被传送及保全。
                    </p>

                    <h5 id="amendments">
                        条款修改
                    </h5>
                    <p class="text-justify">
                        我们保留在有需要时修改或更生本网站提供的条款之权利。如条款有任何修改或更生，阁下可在页尾能看到更生及生效日期。而被修改或更生的内容亦将同时生效。阁下在修改或更生条款后继续浏览或使用本网站即代表阁下同意相关修改或更生。
                    </p>

                    <h5 id="contact">联络我们</h5>
                    <p class="text-justify">
                        如阁下对本条款有任何疑问，请在办公时间星期一至五早上9点至下午6点，通过以下方式联络我们：
                    </p>

                    <dt>
                        电邮：
                    </dt>
                    <dd>
                        marketing@marierskincare.com
                    </dd>
                    <dt>
                        电话：
                    </dt>
                    <dd>
                        (+852)3583-2928
                    </dd>
                    <dt>
                        传真：
                    </dt>
                    <dd>
                        (+852)2619-9972
                    </dd>

                    <p class="text-justify">
                        本私隐政策受香港特别行政区法律监管。
                    </p>
                    <p class="text-justify">
                        最后更新日期为 2018 年 1 月 31 日
                    </p>

                    <p class="text-justify">
                        I-Skincare Company Limited 保留一切权力。
                    </p>
                    <p class="text-justify">
                        使用此网站表示阁下同意接受本使用条款及细则和私隐政策。
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "Privacy",
        components: {
            ViewTitle
        },
        metaInfo: {
            title: "私隱政策",
        },
    }
</script>

<style lang="scss" scoped>
    section.privacy {
        ul.nav {
            top: 65px;
        }
    }
</style>
